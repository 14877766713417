$background--color: #1e2023;
$icon--color: #1e2023;
$font--color: #ffffff;
$font--color--active: #000000;
$font--primary: "Fira Sans", sans-serif;
$transition--length: 0.8;
$font--size--calc: calc(2vw + 10px);
$menu--items--count: 4;

.outer-menu {
  position: relative;
  z-index: 99;
}

.outer-menu .checkbox-toggle {
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 3;
  cursor: pointer;
  width: 30px;
  height: 30px;
  opacity: 0;
}

.menu-icon {
  $size: 30px;
  height: $size;
  width: $size;
  position: absolute;
  z-index: 2;
  right: 13px;
  top: 13px;
  cursor: pointer;
  &__line {
    height: 2px;
    width: $size;
    display: block;
    background-color: $font--color;
    margin-bottom: 4px;
    transition: transform 0.2s ease, background-color 0.5s ease;
  }
  &__line-left {
    width: $size / 2;
  }
  &__line-right {
    width: $size / 2;
    float: right;
  }
}

.outer-menu .checkbox-toggle:checked ~ {
  .menu-icon {
    .menu-icon__line {
      transform: translateX(0px) rotate(-45deg);
    }
    .menu-icon__line-left {
      transform: translateX(1px) rotate(45deg);
    }
    .menu-icon__line-right {
      transform: translateX(-2px) rotate(45deg);
    }
  }
}

.outer-menu .menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.outer-menu .checkbox-toggle:checked ~ .menu {
  pointer-events: auto;
  visibility: visible;
}

.outer-menu .menu > div {
  width: 200vw;
  height: 200vw;
  color: #fefefe;
  background: rgba(0, 18, 51, 0.95);
  border-radius: 50%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-box-flex: 0;
  flex: none;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.outer-menu .checkbox-toggle:checked ~ .menu > div {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-duration: 0.75s;
  transition-duration: 0.75s;
}

.outer-menu .menu > div > div {
  text-align: center;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  font-weight: 200;
  cursor: pointer;
  .nav__list {
    margin: 0;
    padding: 0;
  }
  .nav__list-item {
    font-size: $font--size--calc;
    position: relative;
    display: block;
    transition-delay: $transition--length + s;
    opacity: 0;
    transform: translate(0%, 100%);
    transition: opacity 0.2s ease, transform 0.3s ease;
    margin: 0 0 20px 0;
    a {
      color: $font--color;
    }
    @media screen and (min-width: 568px) {
      display: inline-block;
      margin: 0 10px 0;
    }
    @media screen and (min-width: 1024px) {
      margin: 0 20px 0;
    }
    &:before {
      content: "";
      position: absolute;
      background: $font--color;
      width: 20px;
      height: 1px;
      bottom: -50%;
      transform: translate(0%, 0%);
      transition: all 0.3s ease;
      z-index: -1;
    }
    &:hover {
      a {
        color: $font--color;
      }
      &:before {
        width: 100%;
      }
    }
  }
}

.outer-menu .checkbox-toggle:checked ~ .menu > div > div {
  opacity: 1;
  -webkit-transition: opacity 0.4s ease 0.4s;
  transition: opacity 0.4s ease 0.4s;
  .nav__list-item {
    opacity: 1;
    transform: translateX(0%);
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    @for $i from 0 through $menu--items--count {
      &:nth-child(#{$i}) {
        transition-delay: $transition--length * $i / 8 + 0.5 + s;
      }
    }
  }
}
