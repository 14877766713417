
/*========= TESTIMONIALS START =========*/
.testimonialsWide {
    background: #333333 url(./../../assets/img/testimonial/bg.jpg) no-repeat center center;
    background-size: cover;
    color: #fff;
    padding-top: 50px;
    padding-bottom: 30px;
    @media screen and(min-width: 568px) {
      padding-top: 0px;
    }
    @media screen and(min-width: 736px) {
      padding-top: 50px;
    }
    @media screen and(min-width: 768px) {
      margin-top: 100px;
    }
    @media screen and(min-width: 1024px) {
      padding-top: 70px;
    }
  }
  .testimonials .carousel-caption {
    position: relative;
    left: 0;
    right: 0;
    padding: 40px 0;
    min-height: 270px;
    display: flex;
    align-items: center
  }
  .testimonials h4 {
    color: #fff;
    margin-bottom: 0;
  }
  .testimonials img {
    margin-bottom: 13px;
    width: 100px;
    height: 100px;
  }
  .testimonials small {
    display: block;
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.7);
  }
  .testimonials .btns {
    margin-top: 10px;
  }
  .testimonials .carousel-control-next {
    right: -15%;
    display: none;
    @media screen and (min-width: 768px){
      display: flex;
    }
  }
  .testimonials .carousel-control-prev {
    left: -15%;
    display: none;
    @media screen and (min-width: 768px){
      display: flex;
    }
  }
  /*========= TESTIMONIALS END =========*/