.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: 100vh;
}

.pieces-slider {
  position: relative;
  text-align: center;
  padding: 8rem 0;
}

.js .pieces-slider {
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Make all slides absolutes and hide them */
.js .pieces-slider__slide {
  position: absolute;
  width: 100%;
  height: 100%;
  // right: 100%;
}

/* Define image dimensions and also hide them */
.pieces-slider__image {
  max-width: 800px;
  max-height: 400px;
}

.js .pieces-slider__image {
  visibility: hidden;
}

/* Hide the titles */
.js .pieces-title__text,
.js .pieces-subTitle__text {
  text-indent: -9999px;
}

/* Canvas with viewport width and height */
.js .pieces-slider__canvas, .canvas_link {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  height: 80vh;
  transition: 0.2s opacity;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  @media screen and (min-width: 568px){
    height: 65vh
  }
  @media screen and (min-width: 768px){
    height: 88vh
  }
  @media screen and (min-width: 1024px){
    height: 85vh
  }
}

/* Class for when we resize */
.pieces-slider__canvas--hidden {
  opacity: 0;
  transition-duration: 0.3s;
}

/* Navigation buttons */
.pieces-slider__button {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: 0;
  background-color: #0353a4;
  color: #fff;
  font-family: inherit;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: 0.1s background-color;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  svg {
    fill: #bdbdbd;
  }
}

.pieces-slider__button:hover {
  background: #0466c8;
  svg {
    fill: white;
  }
}

.pieces-slider__button--next {
  left: auto;
  right: 0;
}

/* Hide the buttons when no JS */
.no-js .pieces-slider__button {
  display: none;
}

/* Media queries with styles for smaller screens */
@media screen and (max-width: 720px) {
  .pieces-slider__image {
    max-width: 300px;
  }
}

@media screen and (max-width: 55em) {
  // .pieces-slider__canvas {
  //   width: 100vw;
  //   height: 100vw;
  // }
  .pieces-slider__button {
    width: 60px;
    height: 60px;
  }
}
