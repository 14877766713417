@import url(https://fonts.googleapis.com/css?family=Black+Ops+One);

.notFoundbox { width: 100%; height: 100%;}
.notFoundbox {
  background-image:
    radial-gradient(
      transparent,
      hsla(0, 0%, 0%, 0.85)),
    linear-gradient(
      transparent    75%, 
      hsl(0, 0%, 0%) 75%),
    linear-gradient(left, 
      hsl(  0,   0%, 82%) 14.29%,
      hsl( 54, 100%, 50%) 14.29%,
      hsl( 54, 100%, 50%) 28.57%,
      hsl(184, 100%, 50%) 28.57%,
      hsl(184, 100%, 50%) 42.86%,
      hsl(121,  98%, 42%) 42.86%,
      hsl(121,  98%, 42%) 57.14%,
      hsl(320,  93%, 37%) 57.14%,
      hsl(320,  93%, 37%) 71.43%,
      hsl(349, 100%, 50%) 71.43%,
      hsl(349, 100%, 50%) 85.71%,
      hsl(240, 100%, 38%) 85.71%);
  background-image:
    radial-gradient(
      transparent,
      hsla(0, 0%, 0%, 0.85)),
    linear-gradient(
      transparent    75%, 
      hsl(0, 0%, 0%) 75%),
    linear-gradient(to left, 
      hsl(  0,   0%, 82%) 14.29%,
      hsl( 54, 100%, 50%) 14.29%,
      hsl( 54, 100%, 50%) 28.57%,
      hsl(184, 100%, 50%) 28.57%,
      hsl(184, 100%, 50%) 42.86%,
      hsl(121,  98%, 42%) 42.86%,
      hsl(121,  98%, 42%) 57.14%,
      hsl(320,  93%, 37%) 57.14%,
      hsl(320,  93%, 37%) 71.43%,
      hsl(349, 100%, 50%) 71.43%,
      hsl(349, 100%, 50%) 85.71%,
      hsl(240, 100%, 38%) 85.71%);
  position: relative;
  overflow:hidden;
}
.notFoundbox:before {
  content: '';
  position: absolute;
  display: block;
  background-image:
    linear-gradient(left, 
      hsl(240, 100%, 38%) 14.29%,
      hsl(  0,   0%,  0%) 14.29%,
      hsl(  0,   0%,  0%) 28.57%,
      hsl(320,  93%, 27%) 28.57%,
      hsl(320,  93%, 27%) 42.86%,
      hsl(  0,   0%,  0%) 42.86%,
      hsl(  0,   0%,  0%) 57.14%,
      hsl(184, 100%, 50%) 57.14%,
      hsl(184, 100%, 50%) 71.43%,
      hsl(  0,   0%,  0%) 71.43%,
      hsl(  0,   0%,  0%) 85.71%,
      hsl(  0,  0%, 82%) 85.71%);
  background-image:
    linear-gradient(to left, 
      hsl(240, 100%, 38%) 14.29%,
      hsl(  0,   0%,  0%) 14.29%,
      hsl(  0,   0%,  0%) 28.57%,
      hsl(320,  93%, 27%) 28.57%,
      hsl(320,  93%, 27%) 42.86%,
      hsl(  0,   0%,  0%) 42.86%,
      hsl(  0,   0%,  0%) 57.14%,
      hsl(184, 100%, 50%) 57.14%,
      hsl(184, 100%, 50%) 71.43%,
      hsl(  0,   0%,  0%) 71.43%,
      hsl(  0,   0%,  0%) 85.71%,
      hsl(  0,  0%, 82%) 85.71%);
  height: 5%;
  width: 100%;
  bottom: 24%;
}

/*********************
  STATIC 
*********************/
.static,
.static div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../../assets/notFound.png) repeat center center;
  animation: static 3s steps(5, end) infinite;
  opacity: 0.65;
}

.static div {
  background-size: 100px 100px;
  animation-duration: 2.5s;
}

@keyframes static {
    0% { background-position:   0%   0%; }
   20% { background-position:  25%  15%; }
   40% { background-position:  50%  69%; }
   60% { background-position:  33%  25%; }
   80% { background-position:  72%   4%; }
  100% { background-position:  80%  91%; }
}

/*********************
  SCAN LINES
*********************/
.scan,
.scan:before,
.scan:after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 10%;
  background-color: hsla(0, 0%, 0%, .13);
  box-shadow: 0 0 10px hsla(0, 0%, 0%, .25);
  animation: scan 12s linear infinite;
}

.scan:before,
.scan:after {
  content: '';
  display: block;
  height: 60%;
}

.scan:before {
  top: -350%;
}

.scan:after {
  top: -1100%;
}

@keyframes scan {
    0% { top: -20%; }
  100% { top: 250%; }
}

.notFoundText {
  left: 50%;
  position: absolute;
  bottom: 0%;
  transform: translate( -50%, 0%);
}
.text .wrap {
	fill: #000;
}

.overlay {
	height: 0;
	width: 0;
	overflow: hidden;
	position: absolute;
  & .txt {
    font-size: 6rem;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Black Ops One';
    font-weight: bold;
    text-shadow: 0 -3px 0 #ffffff, 0 1px 1px rgb(66, 66, 66), 0 6px 5px rgba(60, 59, 59, 0.14)
  }
  & .txt2{
     font-size: 1.5rem;
     text-shadow: 0 -1px 0 #cccccc, 0 1px 1px #000000, 0 0px 2px rgb(0, 0, 0)
  }
}
section{
  align-self:center;
  display: flex;
  justify-content: center;
}
.h1{
	position: relative;
	font-size: 5em;
  font-weight:bold;
	line-height: 1;
	display: inline-block;
  width: 200px;
	height: 130px;
  & .fill {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  & .inv {
    position: absolute;
    width: 200px;
    height: 130px;
    top: 0;
    left: 0;
  }
  & .rect {
    fill:hsla(0, 0%, 90%, 1);
  }
  & .clear {
    fill:transparent;
  }
}
