
.pieces {
  display: flex;
  flex-wrap: wrap;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.about-grid {
  --body-text: #585858;
  --body-text-alt: #cf1d56;
  --body-bg: #010101;
  --link-text: #c7c5c5;
  --link-text-hover: #fff;
}

.content {
  --pieces-height: 80vh;
  height: 100vh;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-nav {
  position: absolute;
  width: 95%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  font-family: 'Pacifico', cursive;
  text-align: center;
  @media screen and(min-width: 768px) {
    justify-content: center;
  }
  @media screen and(min-width: 1024px) {
    width: 70%;
  }
  @media screen and(min-width: 1600px) {
    width: 50%;
  }
  h3 {
    font-family: var(--content-font);
    font-size: 2rem;
    text-decoration: underline;
    color: #1e79a5;
    @media screen and(min-width: 768px) {
      font-size: 3rem;
      margin-bottom: 30px;
    }
  }
  h6 {
    font-weight: 300;
    @media screen and(min-width: 768px) {
      font-size: 1.6rem;
      margin-bottom: 30px;
    }
  }
  p {
    font-weight: 300;
    letter-spacing: 0.03em;
    @media screen and(min-width: 768px) {
      font-size: 1rem;
      line-height: 1.7rem;
      margin-bottom: 30px;
    }
    small {
      @media screen and(min-width: 768px) {
        font-weight: 300;
        font-size: 90%;
      }
    }
  }
  .page-nav__item--close {
    margin: 0;
    position: relative;
    svg {
      fill: white;
      margin-right: 10px;
    }
  }
}

.content__title {
  font-size: 4rem;
  margin: 0;
  font-weight: normal;
  position: absolute;
  color: white;
  font-family: 'Pacifico', cursive;
  bottom: 28%;
  letter-spacing: 0.07em;
  cursor: pointer;
  @media screen and (min-width: 667px){
    bottom: 0%;
  }
  @media screen and (min-width: 768px){
    bottom: 20%;
  }
}

.page-nav--open ~ .content__title {
  pointer-events: none;
}

.content .pieces,
.content .piecesContent {
  height: var(--pieces-height);
  width: calc(var(--pieces-height) * calc(1200 / 798));
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: calc(-1 * var(--pieces-height) * calc(1200 / 798) / 2);
  margin-top: calc(-1 * var(--pieces-height) / 1.3);
  pointer-events: none;
  @media screen and (min-width: 667px){
    margin-top: calc(-1 * var(--pieces-height) / 2);
  }
  @media screen and (min-width: 768px){
    margin-top: calc(-1 * var(--pieces-height) / 1.6);
  }
}

@media screen and (max-width: 40em) {
  .content {
    --pieces-height: 50vh;
    height: var(--pieces-height);
    /* margin-bottom: 8em; */
  }
  .content__title {
    font-size: 3rem;
  }
}
